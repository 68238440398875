<template>
    <div class="p-6 loading-table" v-if="showPndingAjaxLoad">
        <spinner-color />
    </div>
    <div v-else>
        <data-table-mission :data="this.$store.state.dataList.missions" :header="header" :body="body" :advancedSearchData="advancedSearchData" />
    </div>
</template>

<script>
import { mapActions } from "vuex";
import DataTableMission from "../components/table-components/DataTableMission.vue"
import SpinnerColor from '@/views/components/table-components/SpinnerColor2.vue'
import globalMixin from '@/mixins/mixins.js'

export default {
    mixins: [globalMixin],
    components: {
        DataTableMission,
        SpinnerColor
    },
    data () {
        return {
            header: [
                {head: 'dateOfCreations', key: 'created_at'},
                {head: 'rightDate', key: 'due_date'},
                {head: 'dueDate', key: 'execution_date'},
                {head: 'status', key: 'status'},
                {head: 'code', key: 'code'},
                {head: 'type', key: 'type'},
                {head: 'captain', key: 'captain_name'},
                {head: 'cash', key: 'amount'},
                {head: 'backup', key: 'pickup_cost'},
                {head: 'actions', key: ''}
            ],
            body: ['created_at','due_date', 'execution_date', 'status', 'code', 'type', 'captain_name', 'amount', 'pickup_cost'],
            advancedSearchData: [
                {
                label: "dateOfCreations",
                nameToEndpoint: "created_at",
                max: "",
                type: "date",
                },
                {
                    // in here the due_date represnt the right date in arabic التاريخ المناسب للمهمة and in the orders pages the due_date represent the theDateOfDelivery in arabic   تاريخ التسليم للطرد
                label: "rightDate",
                nameToEndpoint: "due_date",
                max: "",
                type: "date",
                },
                {
                    // And the execution_date represent the due date in the translation in arabic التاريخ التنفيذ للمهمة
                label: "dueDate",
                nameToEndpoint: "execution_date",
                max: "",
                type: "date",
                },
                { label: "code", nameToEndpoint: "code", max: "", type: "text" },
            ],
        }
    },
    computed: {
    },
    methods: {
      ...mapActions('dataList', ["fetchDataMissions"]),
  },
  async created () {
      let credentials = {
        pageNum: null,
        itemsPerPage: 10
      } 
    this.fetchDataMissions(credentials)
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_loadingTable.scss';
</style>