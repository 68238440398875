<template>
    <vs-sidebar
      data-tour-step="4"
      click-not-close
      position-right
      parent="body"
      default-index="1"
      color="primary"
      class="add-new-data-sidebar items-no-padding"
      spacer
      v-model="isSidebarActiveLocal"
    >
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ $t("advancedSearch") }}</h4>
        <feather-icon
          icon="XIcon"
          @click.stop="isSidebarActiveLocal = false"
          class="cursor-pointer"
        ></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>
  
      <component
        :is="scrollbarTag"
        class="scroll-area--data-list-add-new"
        :settings="settings"
        :key="$vs.rtl"
      >
      <div class="p-6">
        <div v-for="(advancedSearch, index) in advancedSearchData" :key="index">
          <div v-if="advancedSearch.type == 'text'">
            <vs-input
              :label="$t(advancedSearch.label)"
              v-model="advancedSearchObj[advancedSearch.nameToEndpoint]"
              class="mb-5 w-full"
              type="text"
              :maxlenght="advancedSearch.max"
              :name="advancedSearch.label"
            />
          </div>
          <div v-else class="advanced-date">
            <label for="">{{ $t(advancedSearch.label) }} :</label>
            <datepicker
              v-model="advancedSearchObj[advancedSearch.nameToEndpoint]"
              format="dd-MM-yyyy"
            ></datepicker>
          </div>
        </div>
        <div class="mb-5">
          <label class="input-label " >الحالة</label>
          <SelectInputVue
            :options="statuses"
            v-model="advancedSearchObj.status"
          >
          </SelectInputVue>
        </div>
        <div class="mb-5">
          <label class="input-label " >النوع</label>
          <SelectInputVue
            :options="missionTypes"
            v-model="advancedSearchObj.type"
          >
          </SelectInputVue>
        </div>
        <div class="mb-5">
          <label class="input-label " >الكابتن</label>
          <SelectInputVue
            :options="resultCaptians"
            v-model="advancedSearchObj.captain_id"
          >
          </SelectInputVue>
        </div>


      </div>
      </component>
  
      <div class="flex flex-wrap items-center p-6 justify-end" slot="footer">
        <button
          class="mr-6 send-order"
          :class="{ 'opacity-btn1': !isFormValid }"
          @click="

            ()=>{
              submitData()
            }
          "
          :disabled="!isFormValid"
          style="font-family: Cairo"
        >
          بحث
        </button>
        <button class="cancel-btn" @click="initValues()">اعادة تعيين</button>
      </div>
    </vs-sidebar>
  </template>
  
  <script>
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import { mapActions, mapGetters } from "vuex";
  import Datepicker from "vuejs-datepicker";
  import allStatus from "@/views/status/orderStatus";
  import SpinnerColor from "@/views/components/table-components/SpinnerColor2.vue";
  import $ from "jquery";
  import landLine from "@/mixins/landLine.js";
  import removeSpaceNumber from "@/mixins/removeSpaceNumber.js";
  import gTag from "@/mixins/gtag.js";
  import validationPhone from "@/mixins/validationPhone.js";
  import SelectInputVue from "@/layouts/components/SelectInput.vue";
  
  export default {
    name: "add-advanced-search",
    mixins: [landLine, removeSpaceNumber, gTag, validationPhone],
    props: {
      isSidebarActive: {
        type: Boolean,
      },
      advancedSearchData: {
        type: Array,
      },
      missionData: {
        type: Object
      },

    },
    components: {
      VuePerfectScrollbar,
      Datepicker,
      SpinnerColor,
      SelectInputVue,
    },
    data() {
      return {
        resultCaptians: [],
        type:[],
        missionTypes:[
            {name:"استلام", id:1},
            {name:"توريد" , id:2},
            {name:"استلام وتوريد", id:3 },
            {name:"مرتجعات / استلام ومرتجعات", id:4},
        ],
        statuses: [
            {name:"مرسلة", id:1},
            {name:"جديدة", id:2},
            {name:"معينة للكابتن", id:3},
            {name:"مسلمة للكابتن", id:4},
            {name:"منفذة", id:5},
            {name:"ملغاة", id:6},
            {name:"منفذة مع الكابتن", id:7},
            {name:"ملغاة مع الكابتن", id:8},
        ],
        searchCaptain: "",
        searchtypes: "",
        searchstatuses: "",
        allStatusPages: allStatus,
        advancedSearchObj: {
            captain_id:"",
            code:"",
            created_at:"",
            code_ot:"",
            due_date:"",
            execution_date:"",
            status:"",
            type:"",
        },
        pageForCaptains: 1,
        loading: false,
  
        settings: {
          // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: 0.6,
        },
      };
    },
    computed: {
      ...mapGetters("dataList", ["captains"]),
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive;
        },
        set(val) {
          if (!val) {
            this.$emit("closeSidebar");
          }
        },
      },
      isFormValid() {
        if (
          !this.errors.any() &&
          (this.advancedSearchObj.captain_id != "" ||
            this.advancedSearchObj.created_at != "" ||
            this.advancedSearchObj.code != "" ||
            this.advancedSearchObj.due_date != "" ||
            this.advancedSearchObj.execution_date != "" ||
            this.advancedSearchObj.status != "" ||
            this.advancedSearchObj.type != "")
        ) {
          return true;
        } else {
          return false;
        }
      },
      scrollbarTag() {
        return this.$store.getters.scrollbarTag;
      },
    },
    methods: {
      ...mapActions("dataList", [
        "advancedSearch",
        "getAllCaptains",
        "missionsAdvancedSearch"
      ]),

      initValues() {
        Object.keys(this.advancedSearchObj).forEach((key) => {
          this.advancedSearchObj[key] = "";
        });
      },
      submitData() {
        // googleEventTrack(
        //     'الضغط على زر البحث داخل البحث المتقدم',
        //     'navbar',
        //     'advanced_search_sidebar'
        // )
        this.$emit("search", this.advancedSearchObj)
        this.$emit("closeSidebar");
      },
    },
    async created() {
      if (this.captains.length == 0) {
        await this.getAllCaptains();
      }
      this.resultCaptians = this.captains;
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/scss/vuexy/_advancedSearch.scss";
  @import "@/assets/scss/vuexy/_editOrder.scss";
  </style>
  <style lang="scss">
  .advanced-date {
    .vdp-datepicker {
      div {
        input {
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 0.3125rem;
          height: 2.4375rem;
          padding: 0 0.75rem;
          margin-bottom: 1.25rem;
          color: rgba(0, 0, 0, 0.7);
          width: 100%;
        }
        input::placeholder {
          color: rgba(0, 0, 0, 0.7) !important;
          opacity: 50%;
          font-size: 0.9rem;
          line-height: 1.3125rem;
          font-family: "cairo";
        }
      }
    }
    label {
      font-size: 0.85rem;
      margin-bottom: 2px;
      color: rgba(0, 0, 0, 0.7);
      line-height: 1.3125rem;
      padding-left: 5px;
      display: block;
    }
  }
  .vs-con-input {
    input {
      height: 2.4375rem;
    }
  }
  .input-select {
    position: relative;
    label {
      padding-left: 5px;
      font-size: 0.85rem;
      color: rgba(0, 0, 0, 0.6);
    }
    input {
      cursor: pointer;
      width: 100%;
      border: 0.0625rem solid #d8d6de;
      border-radius: 0.3125rem;
      height: 2.4375rem;
      padding: 0 0.75rem;
      margin-bottom: 1.5rem;
      &::placeholder {
        color: #6e6b7b;
        opacity: 50%;
        font-size: 0.75rem;
        line-height: 1.3125rem;
      }
    }
    svg {
      position: absolute;
      top: 35px;
      right: 12px;
    }
    .select-options {
      display: none;
      max-height: 260px;
      overflow: auto;
      border: 1px solid #eee;
      padding: 4px;
      padding-top: 6px;
      padding-bottom: 6px;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
      transform: translateY(-20px);
      .inner-input {
        cursor: default;
        margin-bottom: 0px;
      }
      li {
        background: transparent;
        padding: 5px;
        width: 100%;
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  .search-input {
    .con-select {
      width: 100%;
      .input-select-con {
        input {
          width: 100%;
          border: 0.0625rem solid #d8d6de;
          border-radius: 0.3125rem;
          height: 2.4375rem;
          padding: 0 0.75rem;
          margin-bottom: 1.5rem;
        }
        input::placeholder {
          color: #6e6b7b;
          opacity: 50%;
          font-size: 0.75rem;
          line-height: 1.3125rem;
          font-family: "cairo";
        }
        .vs-select--icon {
          top: 33%;
        }
      }
    }
  }
  .vs-select--options {
    z-index: 9999999999;
    right: 21.6px;
    input {
      font-family: "cairo";
      color: #6e6b7b;
      width: 100%;
      border: 0.0625rem solid #d8d6de;
      border-radius: 0.3125rem;
      height: 2.4375rem;
      padding: 0 0.75rem;
      margin-bottom: 0.625rem;
    }
    input::placeholder {
      color: #6e6b7b;
      opacity: 50%;
      font-size: 0.75rem;
      line-height: 1.3125rem;
      font-family: "cairo";
    }
    div {
      li {
        button {
          span {
            font-family: "cairo";
          }
        }
      }
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  </style>
  