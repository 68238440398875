<template>
  <div id="data-list-list-view" class="data-list-container">
    <div v-if="loading" class="loading-pagination">
      <SpinnerColor />
    </div>
    <add-advanced-search :advancedSearchData="advancedSearchData" :isSidebarActive="isSidebarActive" @closeSidebar="toggleDataSidebar" @search="advancedSeachMission" />
    <vs-table ref="table" multiple v-model="selected" noDataText="" :max-items="missionData.itemsPerPage" :data="data">
        <div slot="header" class="flex flex-wrap items-center flex-grow">
          <div class="flex flex-wrap-reverse justify-between items-center data-list-btn-container w-full" style="position: relative;">
            <!-- TITLE TABLE -->
            <div>
              <feather-icon icon="PackageIcon" svgClasses="h-4 w-4"></feather-icon>
              <h3 class="font-semibold ml-3" style="display: inline-block; font-size: 1.125rem;">{{ $t('missions') }}</h3>
            </div>
            <div class="btn-group flex items-center">

              <button  @click="openAdvancedSearch" class="advanced-search flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.825" height="16.653" viewBox="0 0 16.825 16.653">
                  <g id="search" transform="translate(0.956 1)">
                    <circle id="Ellipse_274" data-name="Ellipse 274" cx="6" cy="6" r="6" transform="translate(0.044)" fill="none" stroke="#31006f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <line id="Line_69" data-name="Line 69" x1="3.469" y1="3.469" transform="translate(10.986 10.77)" fill="none" stroke="#31006f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="Icon_awesome-plus" data-name="Icon awesome-plus" d="M5.162,4.434H3.375V2.647a.4.4,0,0,0-.4-.4h-.4a.4.4,0,0,0-.4.4V4.434H.4a.4.4,0,0,0-.4.4v.4a.4.4,0,0,0,.4.4H2.184V7.412a.4.4,0,0,0,.4.4h.4a.4.4,0,0,0,.4-.4V5.625H5.162a.4.4,0,0,0,.4-.4v-.4A.4.4,0,0,0,5.162,4.434Z" transform="translate(3.676 0.971)" fill="#31006f"/>
                  </g>
                </svg>
                {{ $t('advancedSearch') }}
                <i>
                  <svg data-v-7f6390f4="" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left h-4 w-4"><polyline data-v-7f6390f4="" points="15 18 9 12 15 6"></polyline></svg>
                </i>
              </button>
              <button  @click="resetSearch" class="reset flex items-center">

                {{ $t('resetSearch') }}

              </button>
            </div>
          </div>
        </div>
        
      <template slot="thead">
        <vs-th :key="index" v-for="(head, index) in header" :sort-key="head.key">{{ $t(head.head) }}</vs-th>
      </template>
          <!-- MISSIONS -->
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in allMissions">
            <vs-td :key="index" v-for="(td, index) in body" >
              <p v-if="td == 'code'" @click.stop="toMissionDetails(tr.id)" class="product-due_date font-medium truncate code">{{ tr[td] }}</p>
              <p v-else class="product-due_date font-medium truncate">{{ missionTypeAndStatus(tr, td) }}</p>
              <p @click.stop=""  v-if="tr[td] == null">
                <vs-chip class="product-sender" style="font-size: 0.5625rem;" >لا يوجد</vs-chip>
              </p>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <feather-icon v-if="tr.status == 1" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="popupActiveMethod(tr.id)" />
              <vx-tooltip v-else :text="`لا يمكن حذف مهمة حالتها : ${reasonNotDelete(tr.status)}`" position="right" delay=".1s">
                <i style="opacity: 0.6; cursor: no-drop;">
                  <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 stroke-current" class="ml-2"/>
                </i>
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </tbody>
        <!-- Popup Confirmation -->
        <vs-popup class="holamundo"  :title="$t('areYouDelete')" :active.sync="popupActive" @close="triggerClose" ref="popupcontent">
          <p class="mb-1">{{ $t('ChooseReasonCancellation') }}</p>
          <div class="mb-4" v-if="!loadReasons">
              <SelectInputVue
                :options="mission_canelation_reasons"
                v-model="cancelationReason"
                v-validate="'required'"
                name="government"
                @action="infiniteHandler"
                :paginate="true"
              >
              </SelectInputVue>
              <span style="font-size: 0.7rem" class="text-danger font-bold">{{
                errors.first("cancelationReason")
              }}</span>
            <p class="text-danger font-bold" style="font-size: 10px;" v-if="err_mission_cancel_reasons">{{ err_mission_cancel_reasons }}</p>
          </div>
          <div class="my-4" v-else>
            <SpinnerColor />
          </div>
          <vs-button @click.stop="deleteData()" :disabled="!cancelationReason" color="danger" class="mr-3 delete-btn" type="filled">{{ $t('accept') }}</vs-button>
          <vs-button @click="popupActive=false" color="primary" type="filled">{{ $t('cancel') }}</vs-button>
        </vs-popup>
    </vs-table>
    <!-- Loading Search -_- -->
    <div class="p-6 loading-table" v-if="loadAdvanceMission">
      <SpinnerColor />
    </div>

    <div v-if="hasData && !checkSearch" class="flex justify-between items-center px-6 responsive-footer">
      <!-- ITEMS PER PAGE -->
      <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
        <div style="font-family: 'Cairo', sans-serif !important; padding: 0.75rem !important; border-radius: 0.25rem !important;" class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
          <span class="mr-2">{{ currentPage }} - {{ missionData.itemsPerPage * missionData.pageNum }} {{ $t('of') }} {{ feed_count_missions }}</span>
          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
        </div>
        <vs-dropdown-menu>
          <ul style="min-width: 8rem">
            <vs-dropdown-item @click="missionData.itemsPerPage=10"  class="m-0 p-0">
              <li
                class="flex py-4 px-4 cursor-pointer profile-drop">
                <span>10</span>
              </li>
            </vs-dropdown-item>
            <vs-divider class="m-0" />
            <vs-dropdown-item @click="missionData.itemsPerPage=20"  class="m-0 p-0">
              <li
                class="flex py-4 px-4 cursor-pointer profile-drop">
                <span>20</span>
              </li>
            </vs-dropdown-item>
            <vs-divider class="m-0" />
            <vs-dropdown-item @click="missionData.itemsPerPage=50"  class="m-0 p-0">
              <li
                class="flex py-4 px-4 cursor-pointer profile-drop">
                <span>50</span>
              </li>
            </vs-dropdown-item>
            <vs-divider class="m-0" />
            <vs-dropdown-item @click="missionData.itemsPerPage=100"  class="m-0 p-0">
              <li
                class="flex py-4 px-4 cursor-pointer profile-drop">
                <span>100</span>
              </li>
            </vs-dropdown-item>
          </ul>
        </vs-dropdown-menu>
      </vs-dropdown>
      <div>
        <vs-pagination
          :total="totalPage"
          v-model="missionData.pageNum"
          :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'"
          :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'" 
        />
      </div>
    </div>
    <empty-data v-else-if="!hasData" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex"
import EmptyData from './EmptyData.vue'
import SpinnerColor from './SpinnerColor2.vue'
import globalMixin from '@/mixins/mixins.js'
import emptyTable from '@/mixins/datatableEmpty.js'
import helper from '@/mixins/helper.js'
import notify from '@/mixins/notifyState.js'
import SelectInputVue from "@/layouts/components/SelectInput.vue"
import AddAdvancedSearch from "../../missions/AddAdvancedSearch.vue"

export default {
  name: 'data-table-mission',
  mixins: [globalMixin, emptyTable, helper, notify],
  components: {
    SpinnerColor,
    EmptyData,
    SelectInputVue,
    AddAdvancedSearch
  },
  props: {
    data: {
      type: Array,
    },
    header: {
      type: Array,
      required: true
    },
    body: {
      type: Array,
      required: true
    },
    advancedSearchData: {
      type: Array
    },
  },
  data () {
    return {
      selected: [],
      checkSelect: false,
      isMounted: false,
      popupActive: false,
      id: '',
      missionData: {
        pageNum: 1,
        itemsPerPage: 10,
      },
      advancedSearchObj: '',
      search: '',
      reasonOfCantDelete: '',
      checkSearch: false,
      loadAdvanceMission: false,
      errSearch: false,
      errSearchMsg: false,
      loading: false,
      loadReasons: false,
      cancelationReason: null,
      isSidebarActive: false,
      isFiltering: false,
    }
  },
  computed: {
    ...mapGetters('dataList', ['missions', 'feed_count_missions', 'search_mission', 'max_mission_updated_at', 'message', 'mission_canelation_reasons', 'err_mission_cancel_reasons']),
    ...mapState('dataList', ['next_page_url_cancelation_reasons']),
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.data.length
    },
    totalPage (){
      return Math.ceil(this.feed_count_missions / this.missionData.itemsPerPage)
    },
    allMissions() {
      if(this.hasData && !this.checkSearch && !this.loadAdvanceMission) {
        return this.data
      }else if (this.hasData && this.search != '' && this.checkSearch && !this.loadAdvanceMission) {
        return this.search_mission
      }
    },
  },
  watch: {
    'missionData.pageNum': {
      handler() {
        this.updtaeMissions()
      },
      deep: true
    },
    'missionData.itemsPerPage': {
      handler() {
        this.updtaeMissions()
      },
      deep: true
    },
    missions: {
      handler: function() {
        this.tableHasData(this.missions)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('dataList', ["deleteMission", "fetchDataMissions", "searchMission", "cancelMissionReasons", "missionTerminationReasons", "fetchDataMissions", "missionsAdvancedSearch"]),
    async deleteData () {
      if(this.cancelationReason && !this.errors.first("cancelationReason")) {
        if(this.checkSelect == true){
          let myId = ''
          this.selected.forEach(element => {
            myId += element.id+'|'
          });
          await this.deleteMission({id: myId, reasonId: this.cancelationReason})
          this.selected = []
          this.popupActive = false
        }else{
          await this.deleteMission({id: this.id, reasonId: this.cancelationReason})
          this.popupActive = false
        }
        if(this.$store.state.dataList.successApi == 1){
          this.notifySuccess("success");
        }else{
          this.notifyFaild("faild");
        }
      }
      this.cancelationReason = null
    },
    triggerClose () {
      this.$refs.popupcontent.$el.children[1].children[1].scrollTop = 0
    },
    async popupActiveMethod (id, type){
      this.popupActive = true;
      await this.missionTerminationReasons()
      if(type === 2){
        this.checkSelect = true
      }else{
        this.checkSelect = false
        this.id = id
      }
    },
    toMissionDetails(id){
      this.$router.push({ path: `/MissionDetails/${id}` })
    },
    reasonNotDelete (status) {
      if(status == 2){
        return 'جديدة'
      }else if (status == 3){
        return 'معينة للكابتن'
      }else if (status == 4){
        return 'مسلمة للكابتن'
      }else if (status == 5){
        return 'منفذة'
      }else if (status == 6){
        return 'ملغاة'
      }else if (status == 7){
        return 'منفذة مع الكابتن'
      }else{
        return 'ملغاة مع الكابتن'
      }
    },
    async checkAdvanceSearch(){
      this.checkSearch = false
    },
    missionTypeAndStatus(tr, td) {
      if (td == "type") {
        if (tr.type == 1) return "استلام";
        else if (tr.type == 2) return "توريد";
        else if (tr.type == 3) return "استلام وتوريد";
        else return "مرتجعات / استلام ومرتجعات";
      } else if (td == "status") {
        if (tr.status == 1) return "مرسلة";
        else if (tr.status == 2) return "جديدة";
        else if (tr.status == 3) return "معينة للكابتن";
        else if (tr.status == 4) return "مسلمة للكابتن";
        else if (tr.status == 5) return "منفذة";
        else if (tr.status == 6) return "ملغاة";
        else if (tr.status == 7) return "منفذة مع الكابتن";
        else if (tr.status == 8) return "ملغاة مع الكابتن";
      } else {
        return tr[td];
      }
    },
    async updtaeMissions() {
      this.loading = true
      if(this.isFiltering){
        let obj = {
          ...this.missionData,
          ...this.advancedSearchObj
        }
        await this.missionsAdvancedSearch(obj)
      }else{
        await this.fetchDataMissions(this.missionData)
      }
      this.loading = false
    },
    async infiniteHandler($state) {
      if (!this.next_page_url_cancelation_reasons) {
        $state.complete();
      } else {
        await this.cancelMissionReasons(true);
        $state.loaded();
      }
    },
    toggleDataSidebar(val = false) {
      this.isSidebarActive = val
    },
    openAdvancedSearch() {
      this.toggleDataSidebar(true);
    },
    async resetSearch() {
      this.loading = true
      this.isFiltering = false
      Object.keys(this.advancedSearchObj).forEach((key) => {
        this.advancedSearchObj[key] = "";
      });
      this.missionData.pageNum = 1
      await this.fetchDataMissions(this.missionData)
      this.loading = false
    },
    async advancedSeachMission(advancedSearchObj) {
      // googleEventTrack(
      //     'الضغط على زر البحث داخل البحث المتقدم',
      //     'navbar',
      //     'advanced_search_sidebar'
      // )
      this.loading = true
      this.missionData.pageNum = 1
      this.isFiltering = true
      this.advancedSearchObj = advancedSearchObj
      if (advancedSearchObj.created_at != "") {
        advancedSearchObj.created_at = new Date(
          advancedSearchObj.created_at
        )
          .toISOString()
          .split("T")[0];
      }
      if (advancedSearchObj.due_date != "") {
        advancedSearchObj.due_date = new Date(
          advancedSearchObj.due_date
        )
          .toISOString()
          .split("T")[0];
      }
      if (advancedSearchObj.execution_date != "") {
        advancedSearchObj.execution_date = new Date(
          advancedSearchObj.execution_date
        )
          .toISOString()
          .split("T")[0];
      }
      
      let obj = {
        ...this.missionData,
        ...advancedSearchObj
      }
      await this.missionsAdvancedSearch(obj)
      this.loading = false

    },
  },
  mounted () {
    this.isMounted = true
  },
}
</script>


<style lang="scss">
@import '@/assets/scss/vuexy/_dataTable.scss';
.con-vs-popup .vs-popup {
  max-height: 450px;
  .custom-select {
    display: block;
    height: auto;
    .selected {
      min-height: 37px;
    }
    .items {
      position: unset;
    }
  }
}
.vs-popup--content {
  max-height: 450px;
  height: 100%;
}
</style>
<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_variables.scss';

@import '@/assets/scss/vuexy/_loadingTable.scss';
.search-mission{
  width: 38%;
  height: 38px;
  border: 1px solid #D8D6DE;
  border-radius: 4px;
  padding: 0 0.75rem;
  color: #212121;
  margin-top: 15px;
  margin-bottom: 15px;
}
.icon-advanced-search{
  position: absolute;
  right: 10px;
  top: 25px;
  cursor: pointer;
}
.err-search {
  position: absolute;
  right: 310px;
  bottom: -21px;
  z-index: 999;
  min-width: 200px;
  color: red;
  font-size: 12px;
}
.delete-btn:disabled,
.delete-btn[disabled]{
  opacity: .5;
}
.advanced-search, .reset{
  height: 38px;
  background-color: #FFF;
  box-shadow: 0px 4px 24px #0000000D;
  color: $mainColor1;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  padding: 0px 12px;
  border: none;
  margin-right: 0;
  cursor: pointer;
  border-radius: 4px;
  svg{
    margin-right: 8px;
  }
  i{
    height: 14px;
    svg{
      margin-left: 8px;
      margin-right: 0;
    }
  }
}
</style>